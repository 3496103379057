/**
 * IMPORTS
 */

import React, { useEffect } from 'react';
import { useTween } from 'react-use';

import { useEventTracker } from '../../hooks';
import { useTranslation } from '../../utils/translate';
import SpinningWheel from '../common/SpinningWheel';

/**
 * CORE
 */

function WaitScreen() {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracker();

  const percent = useTween('linear', 10000) * 100;

  useEffect(() => {
    trackEvent({ eventType: 'STEP_START', step: 'WAIT' })
  }, [trackEvent])

  return (
    <SpinningWheel
      text={t('common.rendering') as string}
      centerLabel={`${percent.toFixed(0)}%`}
    />
  );
}

export default WaitScreen;
