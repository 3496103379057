/**
 * IMPORTS
 */

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isIOS, isChrome, osVersion, withOrientationChange } from 'react-device-detect';
import { Center, Container, Stack, Text } from '@chakra-ui/react';
import QRCode from 'qrcode.react';

import { MSState } from './reducers';
import { getCurrentStep, Step } from './selectors';

import CaptureScreen from './components/capture/CaptureScreen';
import UploadScreen from './components/upload/UploadScreen';
import ThemeScreen from './components/theme/ThemeScreen';
import WaitScreen from './components/wait/WaitScreen';
import ShareScreen from './components/share/ShareScreen';
import FullHeightFlex from './components/common/FullHeightFlex';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import { useTranslation } from './utils/translate';
import MinHSpacer from './components/common/MinHSpacer';

/**
 * TYPES
 */

type AppProps = ConnectedProps<typeof connector> & { isPortrait: boolean, isLandscape: boolean };

/**
 * CORE
 */

const connector = connect(
  (state: MSState) => ({
    step: getCurrentStep(state),
  }),
);

/**
 * CORE
 */

function getStepComponent(step: Step) {
  switch (step) {
    case Step.CAPTURE:
      return <CaptureScreen />;
    case Step.UPLOAD:
      return <UploadScreen />;
    case Step.THEME:
      return <ThemeScreen />;
    case Step.WAIT:
      return <WaitScreen />;
    case Step.SHARE:
      return <ShareScreen />;
  }
}

function ErrorText({ errorKey }: { errorKey: string }) {
  const { t } = useTranslation();
  return (
    <Text textAlign="center">
      {t(`errors.${errorKey}`)}
    </Text>
  );
}

function BrowserError({ errorKey }: { errorKey: string }) {
  return (
    <Stack spacing="1rem">
      <ErrorText errorKey={errorKey} />
      <Center>
        <QRCode size={256} value={window.location.origin} />
      </Center>
    </Stack>
  );
}

function InvalidScreen({ errorKey }: { errorKey: string }) {
  const Content = errorKey === 'not-mobile' ? BrowserError : ErrorText;

  return (
    <FullHeightFlex direction="column">
      <Header />

      <MinHSpacer />

      <Container>
        <Content errorKey={errorKey} />
      </Container>

      <MinHSpacer />

      <Footer />
    </FullHeightFlex>
  );
}

function App({ step, isLandscape }: AppProps) {
  // if (!isMobile) { // enable experience for kiosks
  //   return <InvalidScreen errorKey="not-mobile" />;
  // }

  if (
    isIOS
    && (osVersion.localeCompare('14.3', undefined, { numeric: true }) < 0) // os < 14.3
    && isChrome
  ) {
    return <InvalidScreen errorKey="ios-chrome" />;
  }

  if (isLandscape) {
    return <InvalidScreen errorKey="not-portrait" />;
  }

  const component = getStepComponent(step);
  return component;
}

export default connector(withOrientationChange(App));
