/**
 * IMPORTS
 */

import { extendTheme } from '@chakra-ui/react';

/**
 * CORE
 */

// @see https://smart-swatch.netlify.app/ (get JS object)
const colors = {
  brand: {
    50: '#dbf2ff',
    100: '#b4dbfc',
    200: '#89c7f3',
    300: '#5cb5ed',
    400: '#32a5e7',
    500: '#1892cd',
    600: '#0a67a1',
    700: '#004274',
    800: '#002348',
    900: '#000a1e',
  },
  snapchat: {
    50: "#403F00",
    100: "#666500",
    200: "#8C8B00",
    300: "#B3B000",
    400: "#D9D600",
    500: "#FFFC0D",
    600: "#FFFD33",
    700: "#FFFD59",
    800: "#FFFE80",
    900: "#FFFEA6"
  },
  tiktok: {
    50: "#000000",
    100: "#000000",
    200: "#000000",
    300: "#000000",
    400: "#000000",
    500: "#000000",
    600: "#262626",
    700: "#404040",
    800: "#595959",
    900: "#737373"
  },
};

const fonts = {
  body: '"Open Sans",Arial,sans-serif',
  heading: 'Oswald,Helvetica,Arial,sans-serif',
};

const Button = {
  baseStyle: {
    borderRadius: 'full',
  },
};

const Heading = {
  baseStyle: {
    color: 'brand.500',
    textTransform: 'uppercase',
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button,
    Heading,
  },
});

export default theme;
