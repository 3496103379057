/**
 * IMPORTS
 */

import React from 'react';
import { Text, Stack } from '@chakra-ui/react';
import Lottie from 'react-lottie';

import FullHeightFlex from './FullHeightFlex';
import MinHSpacer from './MinHSpacer';
import Footer from './Footer';

import loaderAnimation from '../../assets/loader.json';

/**
 * CORE
 */

interface SpinningWheelProps {
  text: string;
  centerLabel?: string;
}

function SpinningWheel({ text, centerLabel }: SpinningWheelProps) {
  return (
    <FullHeightFlex direction="column">
      <MinHSpacer />
      <Stack align="center">
        <Lottie
          width={75}
          height={75}
          options={{
            loop: true,
            autoplay: true,
            animationData: loaderAnimation,
          }}
        />
        <Text>
          {text}
        </Text>
      </Stack>
      <MinHSpacer />
      <Footer />
    </FullHeightFlex>
  );
}
export default SpinningWheel;
